// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/floating-vue/dist/style.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-popper__wrapper{max-width:480px}@media only screen and (max-width: 480px){.v-popper__wrapper{max-width:100vw}}.v-popper__inner{background:rgba(33,33,33,.7) !important;overflow-wrap:break-word}.v-popper__inner *{overflow-wrap:break-word}.v-popper__arrow-outer{opacity:.7}@media only screen and (max-width: 768px){#launcher-frame{min-width:85px !important;max-width:85px !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
