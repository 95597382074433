
import { LoaderIcon } from 'vue-feather-icons'
import { initiateFreshDesk, openWidget } from '~/fc/FreshDesk'
export default {
    name: 'FreshDeskWidgetLoader',
    components: { LoaderIcon },
    data: () => ({
        loadingFW: false,
        fwLoaded: false
    }),
    computed: {
        info() {
            return this.$store.state.info;
        },
        enablePreview() {
            return (
                this.info.active === "PreviewTemplate" ||
                (this.info.customVariables
                    ? this.info.customVariables.enablePreview
                    : false)
            );
        },
        hasFiles() {
            return !!this.$store.state.list.length;
        },
        dialogIsOpen () { return this.$store.state.store.dialogIsOpen }
    },
    mounted() {
        this.$bus.$on('OPEN_FW', () => {
            if (window.FreshworksWidget) { openWidget() }
            else { this.loadAndOpenFW() }
        })
    },
    methods: {
        loadAndOpenFW() {
            this.loadingFW = true
            window.fwSettings = { 'widget_id': 151000000250 };
            // eslint-disable-next-line no-unused-expressions, no-var
            !function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }()

            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = 'https://widget.freshworks.com/widgets/151000000250.js'
            script.defer = true
            script.async = true
            document.body.appendChild(script)

            const checkFWInterval = setInterval(async () => {
                if (window.FreshworksWidget) {
                    clearInterval(checkFWInterval)
                    await initiateFreshDesk()
                    openWidget()
                    setTimeout(() => {
                        this.loadingFW = false
                        this.fwLoaded = true
                    }, 200)
                }
            }, 500)
        }
    }
}
