
import { XIcon } from 'vue-feather-icons'

export default {
  components: {
    XIcon
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    appName: {
      default: 'video_compressor',
      required: false
    }
  },
  data: () => ({
    showPopup: false,
    loaded: false
  }),
  computed: {
    apps () {
      return {
        video_compressor: {
          name: 'ShrinkVid Video Compressor',
          appText: 'Video Compressor App - ShrinkVid',
          genericName: 'Video Compressor',
          logo: 'https://cdn.freeconvert.com/video_compressor_app_icon.png',
          text: this.$t('compress_videos'),
          parentApp: 'ShrinkVid',
          screenshotFull: 'https://cdn.freeconvert.com/video_compressor_app_screenshot_full.png',
          screenshotHalf: 'https://cdn.freeconvert.com/video_compressor_app_screenshot_half.png',
          googlePlay: 'https://play.google.com/store/apps/details?id=com.freeconvert.video_compressor',
          appStore: 'https://apps.apple.com/app/apple-store/id1560444951?pt=118041337&ct=fc-inhouseads-vcapp&mt=8'
        },
        collage_maker: {
          name: 'Collage Maker App',
          appText: 'Collage Maker App',
          logo: 'https://cdn.freeconvert.com/collage_maker_app_icon.svg',
          parentApp: '',
          screenshotFull: 'https://cdn.freeconvert.com/collage_maker_app_screenshot_full.png',
          screenshotHalf: 'https://cdn.freeconvert.com/collage_maker_app_screenshot_half.png',
          googlePlay: 'https://play.google.com/store/apps/details?id=com.freeconvert.collagemaker',
          appStore: 'https://apps.apple.com/app/apple-store/id1587802950?pt=118041337&ct=fc-inhouse-cmapp&mt=8'
        },
        image_converter: {
          name: 'Image Converter - PixConvert',
          appText: 'Image Converter App - PixConvert',
          genericName: 'Image Converter',
          text: this.$t('convert_images'),
          logo: 'https://cdn.freeconvert.com/image_converter_app_icon.svg',
          parentApp: 'PixConvert',
          screenshotFull: 'https://cdn.freeconvert.com/image_converter_app_screenshot_full.png',
          screenshotHalf: 'https://cdn.freeconvert.com/image_converter_app_screenshot_half.png',
          googlePlay: 'https://play.google.com/store/apps/details?id=com.freeconvert.image_converter',
          appStore: 'https://apps.apple.com/app/apple-store/id1570076731?pt=118041337&ct=fcinhousead-icapp&mt=8'
        }
      }
    },
    device () { return process.client ? this.getMobileOperatingSystem() : null },
    info () { return this.$store.state.info },
    remoteURL () { return this.device && this.device === 'iOS' ? this.app.appStore : this.app.googlePlay },
    app () { return this.apps[this.appName] },
    isMobile () { return this.$store.state.store.isMobile }
  },
  mounted () {
    if (process.client) {
      let visitedBanner = window.localStorage.getItem('visitedAppBanner')
      if (visitedBanner) {
        visitedBanner = JSON.parse(visitedBanner)
        this.showPopup = visitedBanner.length ? !visitedBanner.includes(this.appName + ':' + this.info.slug) : true
      } else {
        this.showPopup = true
      }
    }
    setTimeout(() => { this.loaded = true }, 1000)
  },
  methods: {
    getMobileOperatingSystem () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) { return 'Android' }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { return 'iOS' }
      return 'Android'
    },
    hidePopup () {
      let visitedBanner = window.localStorage.getItem('visitedAppBanner')
      visitedBanner = visitedBanner ? JSON.parse(visitedBanner) : []
      visitedBanner.push(this.appName + ':' + this.info.slug)
      window.localStorage.setItem('visitedAppBanner', JSON.stringify(visitedBanner))
      this.showPopup = false
    }
  }
}
